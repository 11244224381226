var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-business-domestic'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('page-title-operate'), _c('tab-business-domestic'), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "서초꿈나래쉼터",
      "file": "sc"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 학대피해아동쉼터 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 개관일 : 2021년 10월 25일 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 오문원 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 5명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 정원 : 7명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 교육 및 정서지원, 자기결정권 및 사회성 향상 교육, 안정적 일상생활 지원, 상담 및 심리치료, 맞춤형 보호계획수립, 학대아동 보호체계 구축 등 ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "경기광주 아동보호전문기관",
      "file": "gj",
      "url": "https://naver.me/G0JsBt5J"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설유형 : 아동보호전문기관 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 경기도 광주시 행정타운로 64-2, 1층 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설규모 : 200.69 ㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 개관일 : 2021.12. 01 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 박동산 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 14명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 아동학대예방활동, 아동학대 위기개입, 예방교육사업, 아동심리상담 등 ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "파주아동보호전문기관",
      "file": "pj",
      "url": "http://paju1391.kr/"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 경기도 파주시 금바위로 42, 407호(운정법조타운) ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설규모 : 219.3 ㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 개관일 : 2021.12. ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 송윤수 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 9명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 아동학대예방활동, 아동학대 위기개입, 예방교육사업, 아동심리상담 등 ")])])]), _c('business-item', {
    attrs: {
      "imageGrid": "4",
      "tit": "광명아동보호전문기관",
      "file": "gmc",
      "url": "https://gmcchild.cafe24.com/"
    }
  }, [_c('ul', {
    staticClass: "dot-texts page-text"
  }, [_c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 소재지 : 경기도 광명시 금하로526 4층 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설규모 : 419.46㎡ ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 개관일 : 2019.02.22. ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 시설장 : 안동화 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 종사자 : 13명 ")]), _c('li', {
    staticClass: "dot-text"
  }, [_vm._v(" 주요사업 : 아동학대예방활동, 아동학대 위기개입, 예방교육사업, 아동심리상담 등 ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }