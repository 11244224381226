<template>
    <client-page>

        <sub-visual-business-domestic />

        <section class="section">
            <v-container>

                <page-title-operate />

                <tab-business-domestic />

                <business-item imageGrid="4" tit="서초꿈나래쉼터" file="sc">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 학대피해아동쉼터
                        </li>
                        <li class="dot-text">
                            개관일 : 2021년 10월 25일
                        </li>
                        <li class="dot-text">
                            시설장 : 오문원
                        </li>
                        <li class="dot-text">
                            종사자 : 5명
                        </li>
                        <li class="dot-text">
                            정원 : 7명
                        </li>
                        <li class="dot-text">
                            주요사업 : 교육 및 정서지원, 자기결정권 및 사회성 향상 교육, 안정적 일상생활 지원, 상담 및 심리치료, 맞춤형 보호계획수립, 학대아동 보호체계 구축 등
                        </li>
                    </ul>
                </business-item>

                <business-item imageGrid="4" tit="경기광주 아동보호전문기관" file="gj" url="https://naver.me/G0JsBt5J">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            시설유형 : 아동보호전문기관
                        </li>
                        <li class="dot-text">
                            소재지 : 경기도 광주시 행정타운로 64-2, 1층
                        </li>
                        <li class="dot-text">
                            시설규모 : 200.69 ㎡
                        </li>
                        <li class="dot-text">
                            개관일 : 2021.12. 01
                        </li>
                        <li class="dot-text">
                            시설장 : 박동산
                        </li>
                        <li class="dot-text">
                            종사자 : 14명
                        </li>
                        <li class="dot-text">
                            주요사업 : 아동학대예방활동, 아동학대 위기개입, 예방교육사업, 아동심리상담 등
                        </li>
                    </ul>
                </business-item>

                <business-item imageGrid="4" tit="파주아동보호전문기관" file="pj" url="http://paju1391.kr/">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            소재지 : 경기도 파주시 금바위로 42, 407호(운정법조타운)
                        </li>
                        <li class="dot-text">
                            시설규모 : 219.3 ㎡
                        </li>
                        <li class="dot-text">
                            개관일 : 2021.12.
                        </li>
                        <li class="dot-text">
                            시설장 : 송윤수
                        </li>
                        <li class="dot-text">
                            종사자 : 9명
                        </li>
                        <li class="dot-text">
                            주요사업 : 아동학대예방활동, 아동학대 위기개입, 예방교육사업, 아동심리상담 등
                        </li>
                    </ul>
                </business-item>

                <business-item imageGrid="4" tit="광명아동보호전문기관" file="gmc" url="https://gmcchild.cafe24.com/">
                    <ul class="dot-texts page-text">
                        <li class="dot-text">
                            소재지 : 경기도 광명시 금하로526 4층
                        </li>
                        <li class="dot-text">
                            시설규모 : 419.46㎡
                        </li>
                        <li class="dot-text">
                            개관일 : 2019.02.22.
                        </li>
                        <li class="dot-text">
                            시설장 : 안동화
                        </li>
                        <li class="dot-text">
                            종사자 : 13명
                        </li>
                        <li class="dot-text">
                            주요사업 : 아동학대예방활동, 아동학대 위기개입, 예방교육사업, 아동심리상담 등
                        </li>
                    </ul>
                </business-item>

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualBusinessDomestic from "@/components/client/sub/sub-visual-business-domestic.vue";
import TabBusinessDomestic from '@/components/client/sub/tab-business-domestic.vue';
import PageTitleOperate from '@/components/client/sub/page-title-operate.vue';
import BusinessItem from '@/components/client/sub/business-item.vue';
export default {
    components: {
        ClientPage,
        SubVisualBusinessDomestic,
        TabBusinessDomestic,
        PageTitleOperate,
        BusinessItem,
    },
};
</script>